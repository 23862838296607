import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.parse-int";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
import _defineProperty from "D:\\Project\\timesharing_bespeak\\admin\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import d2MenuSide from './components/menu-side';
import d2MenuHeader from './components/menu-header';
import d2Tabs from './components/tabs';
import d2HeaderFullscreen from './components/header-fullscreen';
import d2HeaderLocales from './components/header-locales';
import d2HeaderSearch from './components/header-search';
import d2HeaderSize from './components/header-size';
import d2HeaderTheme from './components/header-theme';
import d2HeaderTitle from './components/header-title';
import d2HeaderUser from './components/header-user';
import d2HeaderLog from './components/header-log';
import d2HeaderColor from './components/header-color';
import { mapState, mapGetters, mapActions } from 'vuex';
import mixinSearch from './mixins/search';
import helper from '@/libs/helper.js';
import axios from '@/plugin/axios';
export default {
  name: 'd2-layout-header-aside',
  mixins: [mixinSearch],
  components: {
    d2MenuSide: d2MenuSide,
    d2MenuHeader: d2MenuHeader,
    d2Tabs: d2Tabs,
    d2HeaderFullscreen: d2HeaderFullscreen,
    d2HeaderLocales: d2HeaderLocales,
    d2HeaderSearch: d2HeaderSearch,
    d2HeaderSize: d2HeaderSize,
    d2HeaderTheme: d2HeaderTheme,
    d2HeaderTitle: d2HeaderTitle,
    d2HeaderUser: d2HeaderUser,
    d2HeaderLog: d2HeaderLog,
    d2HeaderColor: d2HeaderColor
  },
  data: function data() {
    return {
      sysname: "渠道",
      // [侧边栏宽度] 正常状态
      asideWidth: '200px',
      // [侧边栏宽度] 折叠状态
      asideWidthCollapse: '65px',
      options: [],
      value: '',
      currentDate: '2020年第4期'
    };
  },
  created: function created() {//this.loadGuanjian();
    //this.loadAreaList();
    //this.loadData();
  },
  computed: _objectSpread({}, mapState('d2admin', {
    keepAlive: function keepAlive(state) {
      return state.page.keepAlive;
    },
    grayActive: function grayActive(state) {
      return state.gray.active;
    },
    transitionActive: function transitionActive(state) {
      return state.transition.active;
    },
    asideCollapse: function asideCollapse(state) {
      return state.menu.asideCollapse;
    }
  }), {}, mapGetters('d2admin', {
    themeActiveSetting: 'theme/activeSetting'
  }), {
    /**
     * @description 最外层容器的背景图片样式
     */
    styleLayoutMainGroup: function styleLayoutMainGroup() {
      return _objectSpread({}, this.themeActiveSetting.backgroundImage ? {
        backgroundImage: "url('".concat(this.$baseUrl).concat(this.themeActiveSetting.backgroundImage, "')")
      } : {});
    }
  }),
  methods: _objectSpread({}, mapActions('d2admin/menu', ['asideCollapseToggle']), {
    /**
     * 接收点击切换侧边栏的按钮
     */
    handleToggleAside: function handleToggleAside() {
      this.asideCollapseToggle();
    },
    loadData: function loadData() {
      var that, url, data, response, current_account_set_id;
      return regeneratorRuntime.async(function loadData$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              that = this;
              url = helper.apiHost + helper.apiUrl.getAccountSetList;
              data = {};
              _context.next = 5;
              return regeneratorRuntime.awrap(axios(url, {
                params: data
              }));

            case 5:
              response = _context.sent;

              if (response.code == 0) {
                this.options = response.data.dataList;
                this.currentDate = response.data.currentDate;
                current_account_set_id = localStorage.getItem("current_account_set_id");

                if (current_account_set_id) {
                  current_account_set_id = parseInt(current_account_set_id);
                }

                that.value = current_account_set_id;

                if (response.data.length && !current_account_set_id) {
                  that.value = response.data[0].id;
                  localStorage.setItem("current_account_set_id", response.data[0].id);
                }
              } else {}

            case 7:
            case "end":
              return _context.stop();
          }
        }
      }, null, this);
    },
    onAccountSetChange: function onAccountSetChange() {
      var page = this;
      localStorage.setItem("current_account_set_id", page.value);
      location.reload();
    }
  })
};