import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
import util from '@/libs/util';
import helper from '@/libs/helper.js';
import axios from '@/plugin/axios';
export default {
  name: 'app',
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale);

    if (localStorage['admin_id']) {
      this.loadMenu(); //加载左侧菜单
    }
  },
  methods: {
    i18nHandle: function i18nHandle(val, oldVal) {
      util.cookies.set('lang', val);
      document.querySelector('html').setAttribute('lang', val);
    },
    loadMenu: function loadMenu() {
      var data, url, res;
      return regeneratorRuntime.async(function loadMenu$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              //加载菜单
              data = {
                id: localStorage['admin_id']
              };
              console.log('menu data', data);
              url = helper.apiHost + helper.apiUrl.menuList;
              _context.next = 5;
              return regeneratorRuntime.awrap(axios({
                url: url,
                method: 'post',
                data: data
              }));

            case 5:
              res = _context.sent;
              console.log('menu res', res);
              this.$store.commit('d2admin/menu/asideSet', res.data);

            case 8:
            case "end":
              return _context.stop();
          }
        }
      }, null, this);
    }
  }
};