import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "D:\\Project\\timesharing_bespeak\\admin\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
import { get } from 'lodash';
export default {
  data: function data() {
    return {
      uploading: false
    };
  },
  computed: _objectSpread({}, mapState('d2admin/log', ['log'])),
  methods: {
    get: get,
    handleShowMore: function handleShowMore(log) {
      // 打印一条日志的所有信息到控制台
      this.$notify({
        type: 'info',
        title: '日志详情',
        message: '完整的日志内容已经打印到控制台'
      });
      this.$log.capsule('D2Admin', 'handleShowMore', 'primary');
      console.group(log.message);
      console.log('time: ', log.time);
      console.log('type: ', log.type);
      console.log(log.meta);
      console.groupEnd();
    },
    // 日志上传
    handleUpload: function handleUpload() {
      var _this = this;

      this.uploading = true;
      this.$notify({
        type: 'info',
        title: '日志上传',
        message: "\u5F00\u59CB\u4E0A\u4F20".concat(this.log.length, "\u6761\u65E5\u5FD7")
      });
      setTimeout(function () {
        _this.uploading = false;

        _this.$notify({
          type: 'success',
          title: '日志上传',
          message: '上传成功'
        });
      }, 3000);
    }
  }
};