import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.replace";
import "regenerator-runtime/runtime";
import Vue from 'vue';
import VueRouter from 'vue-router'; // 进度条

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '@/store/index';
import util from '@/libs/util.js'; // 路由数据

import routes from './routes'; // fix vue-router NavigationDuplicated

var VueRouterPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
  return VueRouterPush.call(this, location).catch(function (err) {
    return err;
  });
};

var VueRouterReplace = VueRouter.prototype.replace;

VueRouter.prototype.replace = function replace(location) {
  return VueRouterReplace.call(this, location).catch(function (err) {
    return err;
  });
};

Vue.use(VueRouter); // 导出路由 在 main.js 里使用

var router = new VueRouter({
  routes: routes
});
/**
 * 路由拦截
 * 权限验证
 */

router.beforeEach(function _callee(to, from, next) {
  var token;
  return regeneratorRuntime.async(function _callee$(_context) {
    while (1) {
      switch (_context.prev = _context.next) {
        case 0:
          _context.next = 2;
          return regeneratorRuntime.awrap(store.dispatch('d2admin/page/isLoaded'));

        case 2:
          _context.next = 4;
          return regeneratorRuntime.awrap(store.dispatch('d2admin/size/isLoaded'));

        case 4:
          // 进度条
          NProgress.start(); // 关闭搜索面板

          store.commit('d2admin/search/set', false); // 验证当前路由所有的匹配中是否需要有登录验证的

          if (to.matched.some(function (r) {
            return r.meta.auth;
          })) {
            // 这里暂时将cookie里是否存有token作为验证是否登录的条件
            // 请根据自身业务需要修改
            token = util.cookies.get('token');

            if (token && token !== 'undefined') {
              next();
            } else {
              // 没有登录的时候跳转到登录界面
              // 携带上登陆成功之后需要跳转的页面完整路径
              next({
                name: 'login',
                query: {
                  redirect: to.fullPath
                }
              }); // https://github.com/d2-projects/d2-admin/issues/138

              NProgress.done();
            }
          } else {
            // 不需要身份校验 直接通过
            next();
          }

        case 7:
        case "end":
          return _context.stop();
      }
    }
  });
});
router.afterEach(function (to) {
  // 进度条
  NProgress.done(); // 多页控制 打开新的页面

  store.dispatch('d2admin/page/open', to); // 更改标题

  util.title(to.meta.title);
});
export default router;