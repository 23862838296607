import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
import _defineProperty from "D:\\Project\\timesharing_bespeak\\admin\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapState, mapActions } from 'vuex';
import helper from '@/libs/helper.js';
export default {
  computed: _objectSpread({}, mapState('d2admin/user', ['info'])),
  data: function data() {
    return {
      user: {},
      activeCodeVisible: false,
      form: {
        code: ''
      }
    };
  },
  created: function created() {
    this.getUserData();
  },
  methods: _objectSpread({}, mapActions('d2admin/account', ['logout']), {
    changePwd: function changePwd() {
      this.$router.push({
        path: '/adminSetting'
      });
    },
    getUserData: function getUserData() {
      var url, data, response;
      return regeneratorRuntime.async(function getUserData$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              url = helper.apiHost + helper.apiUrl.getUserData;
              data = {};
              _context.next = 4;
              return regeneratorRuntime.awrap(this.axios(url, {
                params: data
              }));

            case 4:
              response = _context.sent;

              if (response.code == 0) {
                this.user = response.data;
              } else {}

            case 6:
            case "end":
              return _context.stop();
          }
        }
      }, null, this);
    },

    /**
     * @description 登出
     */
    logOff: function logOff() {
      this.logout({
        confirm: true
      });
    }
  })
};