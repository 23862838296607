import "core-js/modules/es.array.find";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
// 设置文件
import setting from '@/setting.js';
export default {
  namespaced: true,
  state: {
    // 主题
    list: setting.theme.list,
    // 现在激活的主题 这应该是一个名字 不是对象
    activeName: setting.theme.list[0].name
  },
  getters: {
    /**
     * @description 返回当前的主题信息 不是一个名字 而是当前激活主题的所有数据
     * @param {Object} state state
     */
    activeSetting: function activeSetting(state) {
      return state.list.find(function (theme) {
        return theme.name === state.activeName;
      });
    }
  },
  actions: {
    /**
     * @description 激活一个主题
     * @param {String} themeValue 需要激活的主题名称
     */
    set: function set(_ref, themeName) {
      var state = _ref.state,
          commit = _ref.commit,
          dispatch = _ref.dispatch;
      return new Promise(function _callee(resolve) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                // 检查这个主题在主题列表里是否存在
                state.activeName = state.list.find(function (e) {
                  return e.name === themeName;
                }) ? themeName : state.list[0].name; // 将 vuex 中的主题应用到 dom

                commit('dom'); // 持久化

                _context.next = 4;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'theme.activeName',
                  value: state.activeName,
                  user: true
                }, {
                  root: true
                }));

              case 4:
                // end
                resolve();

              case 5:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },

    /**
     * @description 从持久化数据加载主题设置     * @param {Object} context
     */
    load: function load(_ref2) {
      var state = _ref2.state,
          commit = _ref2.commit,
          dispatch = _ref2.dispatch;
      return new Promise(function _callee2(resolve) {
        var activeName;
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _context2.next = 2;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/get', {
                  dbName: 'sys',
                  path: 'theme.activeName',
                  defaultValue: state.list[0].name,
                  user: true
                }, {
                  root: true
                }));

              case 2:
                activeName = _context2.sent;

                if (!state.list.find(function (e) {
                  return e.name === activeName;
                })) {
                  _context2.next = 7;
                  break;
                }

                state.activeName = activeName;
                _context2.next = 10;
                break;

              case 7:
                state.activeName = state.list[0].name; // 持久化

                _context2.next = 10;
                return regeneratorRuntime.awrap(dispatch('d2admin/db/set', {
                  dbName: 'sys',
                  path: 'theme.activeName',
                  value: state.activeName,
                  user: true
                }, {
                  root: true
                }));

              case 10:
                // 将 vuex 中的主题应用到 dom
                commit('dom'); // end

                resolve();

              case 12:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  },
  mutations: {
    /**
     * @description 将 vuex 中的主题应用到 dom
     * @param {Object} state state
     */
    dom: function dom(state) {
      document.body.className = "theme-".concat(state.activeName);
    }
  }
};