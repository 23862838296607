import "core-js/modules/es.symbol";
import "core-js/modules/es.array.concat";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import _defineProperty from "D:\\Project\\timesharing_bespeak\\admin\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { mapGetters, mapMutations } from 'vuex';
export default {
  computed: _objectSpread({}, mapGetters('d2admin', {
    logLength: 'log/length',
    logLengthError: 'log/lengthError'
  }), {
    tooltipContent: function tooltipContent() {
      return this.logLength === 0 ? '没有日志或异常' : "".concat(this.logLength, " \u6761\u65E5\u5FD7").concat(this.logLengthError > 0 ? " | \u5305\u542B ".concat(this.logLengthError, " \u4E2A\u5F02\u5E38") : '');
    }
  }),
  methods: _objectSpread({}, mapMutations('d2admin/log', ['clean']), {
    handleClick: function handleClick() {
      this.$router.push({
        name: 'log'
      });
    }
  })
};