//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      visible: false,
      value: false,
      isSucceed: false,
      selectDate: '',
      selectTime: '',
      areas: [{
        value: '选项1',
        label: '市内'
      }, {
        value: '选项2',
        label: '市外'
      }, {
        value: '选项3',
        label: '省内'
      }, {
        value: '选项4',
        label: '省外'
      }],
      area: '',
      teams: [{
        value: '选项1',
        label: '+新增团队'
      }, {
        value: '选项2',
        label: '团队1'
      }, {
        value: '选项3',
        label: '团队2'
      }, {
        value: '选项4',
        label: '团队3'
      }, {
        value: '选项5',
        label: '团队4'
      }, {
        value: '选项6',
        label: '北京烤鸭'
      }],
      team: ''
    };
  },
  methods: {
    submit: function submit() {
      var _this = this;

      this.$confirm('确认提交预约?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(function () {
        _this.isSucceed = true;
        /* this.$message({
          type: 'success',
          message: '删除成功!'
        }); */
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    selectTeam: function selectTeam() {
      this.visible = true;
    }
  }
};