import "core-js/modules/es.parse-int";
import "core-js/modules/es.string.match";
import _typeof from "D:\\Project\\timesharing_bespeak\\admin\\node_modules\\@babel\\runtime/helpers/esm/typeof";
//http://localhost:8080/gasline/public/h5/#/
import constValue from '@/libs/constValues.js';
var picHost = 'shop.yabei.lzj';
var apiHost = process.env.VUE_APP_API;
var oauthUrl = 'http://ctyun.ycxxkj.com/gasline/public/index.php/api/oauth/oauth'; //鉴权网址,一般微信端H5才要

var appName = 'local';
var appKey = '32a1ff74699ff2d6ce4c497cb94cb5c8';
var currentEve = 1; //1本地模式，2测试环境,3生产环境

var apiKey = '32a1ff74699ff2d6ce4c497cb94cb5c8';
var apiUrl = {
  uploadFile: '/admin/upload/file',
  uploadImg: '/admin/upload/img',
  //管理员
  adminAdd: '/admin/admin/add',
  adminEdit: '/admin/admin/edit',
  adminDetail: '/admin/admin/detail',
  adminList: '/admin/admin/getList',
  adminDel: '/admin/admin/del',
  adminChangePsw: '/admin/admin/changePwd',
  doLogin: '/admin/index/doLogin',
  menuList: "/admin/menu/getList",
  getIndexData: '/admin/index/getIndexData',
  getUserData: '/admin/setting/index',
  editUserInfo: '/admin/setting/doEditInfo',
  editUserPass: '/admin/setting/doEditPass'
};
var uploadServer = {
  host: 'https://www.ytclian.com/file/public/index.php',
  uploadUrl: '/api/file/upload'
};
var apiCode = {
  unLogin: 1200,
  succ: -1
};

if (currentEve == 1) {
  //如果是开发模式
  //picHost="shop.yabei.lzj";
  // apiHost = 'http://shop.yabei.lzj';
  appName = 'dev';
}

if (currentEve == 2) {
  picHost = 'shop.yabei.lzj'; // apiHost = "";

  appName = 'test';
}

var now = Date.now || function () {
  return new Date().getTime();
};

var isArray = Array.isArray || function (obj) {
  return obj instanceof Array;
};

function checkMobile(sMobile) {
  if (!/^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(sMobile)) {
    return false;
  }

  return true;
}

function isEmpty(obj) {
  //console.log("empty:obj:", obj);
  if (typeof obj == 'undefined' || obj == null || obj == '') {
    //console.log("empty:obj:return ", true);
    return true;
  } else {
    //console.log("empty:obj:return ", false);
    return false;
  }
}

function getRandomCode(length) {
  if (length > 0) {
    var data = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
    var nums = '';

    for (var i = 0; i < length; i++) {
      var r = parseInt(Math.random() * 61);
      nums += data[r];
    }

    return nums;
  } else {
    return false;
  }
}
/**
 * 获取当前时间
 */


function getNow() {
  var fmt = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'time';
  var day2 = new Date();
  day2.setTime(day2.getTime());
  var timeStr = day2.getFullYear() + '-' + (day2.getMonth() + 1) + '-' + day2.getDate() + ' ' + day2.getHours() + ':' + day2.getMinutes() + ':' + day2.getSeconds();

  if (fmt == 'day') {
    timeStr = day2.getFullYear() + '-' + (day2.getMonth() + 1) + '-' + day2.getDate();
  }

  return timeStr;
}
/**
 * 获取格式化日期字符串
 * @param preDays 天数
 * @param day2 日期对象
 * @returns {string}
 */


function formatDayStr() {
  var preDays = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var day2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  // let day2 = new Date()
  console.log("preDays:", preDays);

  if (preDays != 0) {
    day2.setDate(day2.getDate() + preDays);
  }

  console.log("day2:", day2);
  var timeStr = day2.getFullYear() + '-' + (day2.getMonth() <= 9 ? '0' : '') + (day2.getMonth() + 1) + '-' + (day2.getDate() <= 9 ? '0' : '') + day2.getDate() + '';
  console.log("day2_str:", timeStr);
  return timeStr;
} // 是否是微信浏览器


function isWeixin() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == 'micromessenger' ? true : false;
} //组合网址


function cmbUrl(path) {
  return apiHost + '/' + path;
}

function cmbUrlObj(obj, key) {
  var url = apiHost + '/'; // console.log("obj",obj);
  // console.log("key:",key);

  if (obj) {
    // console.log("key in obj",(key in obj));
    if (key in obj) {
      // console.log("obj.key",obj[key]);
      url += obj[key]; // console.log("url:",url);
    }
  }

  return url;
}
/**
 * 获取对象属性值
 * @param {Object} obj
 */


function getObjProperty(obj, property) {
  var defaultValue = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : -1;

  //console.log("getObjProperty", obj);
  //console.log("property", property);
  if (property in obj) {
    return obj[property];
  }

  return defaultValue;
}

function uniShowError(info) {
  uni.showModal({
    content: info,
    showCancel: false,
    success: function success(res) {}
  });
}
/**
 * 设置本地存储
 */


function setLocalStorage(key, value) {
  var prefix = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "ycerp_";
  value = JSON.stringify(value); // console.log(prefix+key+" set value:",value);

  localStorage.setItem(prefix + key, value);
}
/**
 * 获取本地存储
 */


function getLocalStorage(key) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "ycerp_";
  var value = localStorage.getItem(prefix + key);
  value = JSON.parse(value);
  return value;
}
/**
 * 移除本地缓存
 */


function removeLocalStorage(key) {
  var prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "ycerp_";
  localStorage.removeItem(prefix + key);
}

export default {
  picHost: picHost,
  apiHost: apiHost,
  apiUrl: apiUrl,
  appName: appName,
  appKey: appKey,
  oauthUrl: oauthUrl,
  now: now,
  getNow: getNow,
  isArray: isArray,
  checkMobile: checkMobile,
  isEmpty: isEmpty,
  getRandomCode: getRandomCode,
  isWeixin: isWeixin,
  cmbUrl: cmbUrl,
  apiCode: apiCode,
  getObjProperty: getObjProperty,
  uniShowError: uniShowError,
  cmbUrlObj: cmbUrlObj,
  constValue: constValue,
  getUchartsDateArr: function getUchartsDateArr() {
    var days = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : -30;

    if (days > 0) {
      return [formatDayStr(0), formatDayStr(days)];
    }

    return [formatDayStr(days), formatDayStr(0)];
  },
  formatDayStr: formatDayStr,
  uploadServer: uploadServer,
  isJSON: function isJSON(str) {
    if (typeof str == 'string') {
      try {
        var obj = JSON.parse(str);

        if (_typeof(obj) == 'object' && obj) {
          return true;
        } else {
          return false;
        }
      } catch (e) {
        console.log('error：' + str + '!!!' + e);
        return false;
      }
    }

    console.log('It is not a string!');
    return true;
  },
  setLocalStorage: setLocalStorage,
  getLocalStorage: getLocalStorage,
  removeLocalStorage: removeLocalStorage
};