import "core-js/modules/es.array.concat";
import "core-js/modules/es.object.to-string";
import layoutHeaderAside from '@/layout/header-aside'; // 由于懒加载页面太多的话会造成webpack热更新太慢，所以开发环境不使用懒加载，只有生产环境使用懒加载

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);
/**
 * 在主框架内显示
 */


var frameIn = [{
  path: '/',
  redirect: {
    name: 'index'
  },
  component: layoutHeaderAside,
  children: [// 首页
  {
    path: 'index',
    name: 'index',
    meta: {
      auth: true,
      title: '首页'
    },
    component: _import('system/index')
  }, {
    path: '/admin',
    name: 'admin',
    component: function component() {
      return import('@/pages/admin/index');
    },
    meta: {
      auth: true,
      title: '管理员管理',
      cache: true
    }
  }, {
    path: '/admin_pwd',
    name: 'admin_pwd',
    component: function component() {
      return import('@/pages/admin/pwd');
    },
    meta: {
      auth: true,
      title: '修改密码'
    }
  }, {
    path: '/adminSetting',
    name: 'adminSetting',
    component: function component() {
      return import('@/pages/admin/setting');
    },
    meta: {
      auth: true,
      title: '重置密码'
    }
  }, {
    path: '/admin_role',
    name: 'admin_role',
    component: function component() {
      return import('@/pages/admin_role/index');
    },
    meta: {
      auth: true,
      title: '角色管理',
      cache: true
    }
  }, {
    path: '/swiper',
    name: 'swiper_index',
    component: function component() {
      return import('@/pages/swiper/index');
    },
    meta: {
      auth: true,
      title: '轮播图管理',
      cache: true
    }
  }, {
    path: '/swiper/add',
    name: 'swiper_add',
    component: function component() {
      return import('@/pages/swiper/add');
    },
    meta: {
      auth: true,
      title: '轮播图添加'
    }
  }, {
    path: '/swiper/edit',
    name: 'swiper_edit',
    component: function component() {
      return import('@/pages/swiper/edit');
    },
    meta: {
      auth: true,
      title: '轮播图编辑'
    }
  }, {
    path: '/device',
    name: 'device_index',
    component: function component() {
      return import('@/pages/device/index');
    },
    meta: {
      auth: true,
      title: '设备管理',
      cache: true
    }
  }, {
    path: '/device/add',
    name: 'device_add',
    component: function component() {
      return import('@/pages/device/add');
    },
    meta: {
      auth: true,
      title: '设备添加'
    }
  }, {
    path: '/device/edit',
    name: 'device_edit',
    component: function component() {
      return import('@/pages/device/edit');
    },
    meta: {
      auth: true,
      title: '设备编辑'
    }
  }, {
    path: '/device/log',
    name: 'device_log',
    component: function component() {
      return import('@/pages/device/log');
    },
    meta: {
      auth: true,
      title: '设备日志',
      cache: true
    }
  }, {
    path: '/risk_area',
    name: 'risk_area_index',
    component: function component() {
      return import('@/pages/risk_area/index');
    },
    meta: {
      auth: true,
      title: '风险地区管理',
      cache: true
    }
  }, {
    path: '/risk_area/add',
    name: 'risk_area_add',
    component: function component() {
      return import('@/pages/risk_area/add');
    },
    meta: {
      auth: true,
      title: '风险地区添加'
    }
  }, {
    path: '/risk_area/edit',
    name: 'risk_area_edit',
    component: function component() {
      return import('@/pages/risk_area/edit');
    },
    meta: {
      auth: true,
      title: '风险地区编辑'
    }
  }, {
    path: '/question_class',
    name: 'question_class_index',
    component: function component() {
      return import('@/pages/question_class/index');
    },
    meta: {
      auth: true,
      title: '问题类型列表',
      cache: true
    }
  }, {
    path: '/question_class/add',
    name: 'question_class_add',
    component: function component() {
      return import('@/pages/question_class/add');
    },
    meta: {
      auth: true,
      title: '问题类型添加'
    }
  }, {
    path: '/question_class/edit',
    name: 'question_class_edit',
    component: function component() {
      return import('@/pages/question_class/edit');
    },
    meta: {
      auth: true,
      title: '问题类型编辑'
    }
  }, {
    path: '/question',
    name: 'question_index',
    component: function component() {
      return import('@/pages/question/index');
    },
    meta: {
      auth: true,
      title: '问题列表',
      cache: true
    }
  }, {
    path: '/question/add',
    name: 'question_add',
    component: function component() {
      return import('@/pages/question/add');
    },
    meta: {
      auth: true,
      title: '问题添加'
    }
  }, {
    path: '/question/edit',
    name: 'question_edit',
    component: function component() {
      return import('@/pages/question/edit');
    },
    meta: {
      auth: true,
      title: '问题编辑'
    }
  }, {
    path: '/question_answer_record',
    name: 'question_answer_record_index',
    component: function component() {
      return import('@/pages/question_answer_record/index');
    },
    meta: {
      auth: true,
      title: '流调统计'
    }
  }, {
    path: '/travel_code_log',
    name: 'travel_code_log_index',
    component: function component() {
      return import('@/pages/travel_code_log/index');
    },
    meta: {
      auth: true,
      title: '行程码记录',
      cache: true
    }
  }, {
    path: '/user',
    name: 'user_index',
    component: function component() {
      return import('@/pages/user/index');
    },
    meta: {
      auth: true,
      title: '用户管理',
      cache: true
    }
  }, {
    path: '/team',
    name: 'team_index',
    component: function component() {
      return import('@/pages/team/index');
    },
    meta: {
      auth: true,
      title: '团队管理',
      cache: true
    }
  }, {
    path: '/team/detail',
    name: 'team_detail',
    component: function component() {
      return import('@/pages/team/detail');
    },
    meta: {
      auth: true,
      title: '团队详情'
    }
  }, {
    path: '/blacklist',
    name: 'blacklist_index',
    component: function component() {
      return import('@/pages/blacklist/index');
    },
    meta: {
      auth: true,
      title: '黑名单管理',
      cache: true
    }
  }, {
    path: '/blacklist/edit',
    name: 'blacklist_edit',
    component: function component() {
      return import('@/pages/blacklist/edit');
    },
    meta: {
      auth: true,
      title: '黑名单编辑'
    }
  }, {
    path: '/time',
    name: 'time_index',
    component: function component() {
      return import('@/pages/time/index');
    },
    meta: {
      auth: true,
      title: '时段管理',
      cache: true
    }
  }, {
    path: '/time/add',
    name: 'time_add',
    component: function component() {
      return import('@/pages/time/add');
    },
    meta: {
      auth: true,
      title: '时段添加'
    }
  }, {
    path: '/time/edit',
    name: 'time_edit',
    component: function component() {
      return import('@/pages/time/edit');
    },
    meta: {
      auth: true,
      title: '时段编辑'
    }
  }, {
    path: '/place',
    name: 'place_index',
    component: function component() {
      return import('@/pages/place/index');
    },
    meta: {
      auth: true,
      title: '场馆设置'
    }
  }, {
    path: '/place_config',
    name: 'place_config_index',
    component: function component() {
      return import('@/pages/place_config/index');
    },
    meta: {
      auth: true,
      title: '预约设置'
    }
  }, {
    path: '/protocol/edit',
    name: 'protocol_edit',
    component: function component() {
      return import('@/pages/protocol/edit');
    },
    meta: {
      auth: true,
      title: '预约设置'
    }
  }, {
    path: '/config/web',
    name: 'config_web',
    component: function component() {
      return import('@/pages/config/web');
    },
    meta: {
      auth: true,
      title: '系统信息'
    }
  }, {
    path: '/config/wechat',
    name: 'config_wechat',
    component: function component() {
      return import('@/pages/config/wechat');
    },
    meta: {
      auth: true,
      title: '小程序设置'
    }
  }, {
    path: '/config/official',
    name: 'config_official',
    component: function component() {
      return import('@/pages/config/official');
    },
    meta: {
      auth: true,
      title: '公众号设置'
    }
  }, {
    path: '/config/risk',
    name: 'config_risk',
    component: function component() {
      return import('@/pages/config/risk');
    },
    meta: {
      auth: true,
      title: '疫情防控设置'
    }
  }, {
    path: '/appoint_calendar',
    name: 'appoint_calendar_index',
    component: function component() {
      return import('@/pages/appoint_calendar/index');
    },
    meta: {
      auth: true,
      title: '预约日历'
    }
  }, {
    path: '/order',
    name: 'order_index',
    component: function component() {
      return import('@/pages/order/index');
    },
    meta: {
      auth: true,
      title: '预约记录',
      cache: true
    }
  }, {
    path: '/order/detail',
    name: 'order_detail',
    component: function component() {
      return import('@/pages/order/detail');
    },
    meta: {
      auth: true,
      title: '预约详情'
    }
  }, {
    path: '/order/recall',
    name: 'order_recall',
    component: function component() {
      return import('@/pages/order/recall');
    },
    meta: {
      auth: true,
      title: '游客关联回溯'
    }
  }, {
    path: '/order/add',
    name: 'order_add',
    component: function component() {
      return import('@/pages/order/add');
    },
    meta: {
      auth: true,
      title: '预约添加'
    }
  }, {
    path: '/order/edit',
    name: 'order_edit',
    component: function component() {
      return import('@/pages/order/edit');
    },
    meta: {
      auth: true,
      title: '预约编辑'
    }
  }, {
    path: '/order_item',
    name: 'order_item_index',
    component: function component() {
      return import('@/pages/order_item/index');
    },
    meta: {
      auth: true,
      title: '核销管理'
    }
  }, {
    path: '/user_analysis',
    name: 'user_analysis',
    component: function component() {
      return import('@/pages/user_analysis/index');
    },
    meta: {
      auth: true,
      title: '游客分析',
      cache: true
    }
  }, {
    path: '/time_analysis',
    name: 'time_analysis',
    component: function component() {
      return import('@/pages/time_analysis/index');
    },
    meta: {
      auth: true,
      title: '预约时段分析'
    }
  }, {
    path: '/admin_log',
    name: 'admin_log',
    component: function component() {
      return import('@/pages/admin_log/index');
    },
    meta: {
      auth: true,
      title: '系统日志',
      cache: true
    }
  }, {
    path: '/test',
    name: 'test',
    component: function component() {
      return import('@/pages/test/index');
    },
    meta: {
      auth: true,
      title: '测试'
    }
  }, // 系统 前端日志
  {
    path: 'log',
    name: 'log',
    meta: {
      title: '前端日志',
      auth: true
    },
    component: _import('system/log')
  }, // 刷新页面 必须保留
  {
    path: 'refresh',
    name: 'refresh',
    hidden: true,
    component: _import('system/function/refresh')
  }, // 页面重定向 必须保留
  {
    path: 'redirect/:route*',
    name: 'redirect',
    hidden: true,
    component: _import('system/function/redirect')
  }]
}];
/**
 * 在主框架之外显示
 */

var frameOut = [// 登录
{
  path: '/login',
  name: 'login',
  component: _import('system/login')
}, {
  path: '/team_order',
  name: 'team_order',
  component: _import('team_order/index')
}];
/**
 * 错误页面
 */

var errorPage = [{
  path: '*',
  name: '404',
  component: _import('system/error/404')
}]; // 导出需要显示菜单的

export var frameInRoutes = frameIn; // 重新组织后导出

export default [].concat(frameIn, frameOut, errorPage);