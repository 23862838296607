import "core-js/modules/es.symbol";
import "core-js/modules/es.array.filter";
import "core-js/modules/es.object.get-own-property-descriptor";
import "core-js/modules/es.object.get-own-property-descriptors";
import "core-js/modules/es.object.keys";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
import _defineProperty from "D:\\Project\\timesharing_bespeak\\admin\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import dayjs from "dayjs";
import { mapActions } from "vuex";
import localeMixin from "@/locales/mixin.js";
import verifyCode from "@/components/common/verifyCode";
import helper from "@/libs/helper.js";
import base64 from "@/libs/base64-helper.js";
import axios from "@/plugin/axios";
import Base64 from "@/libs/base64-helper.js";
export default {
  mixins: [localeMixin],
  data: function data() {
    return {
      title: process.env.VUE_APP_TITLE,
      timeInterval: null,
      time: dayjs().format("HH:mm:ss"),
      // 快速选择用户
      dialogVisible: false,
      users: [],
      // 验证码初始值
      identifyCode: "1234",
      // 验证码的随机取值范围
      identifyCodes: "1234567890",
      // 表单
      formLogin: {
        username: "",
        password: "",
        code: ""
      },
      pwdFormVisible: false,
      pwdForm: {
        //修改密码表单
        login_name: "",
        pwd: "",
        new_pwd: ""
      },
      checkCode: "",
      // 表单校验
      rules: {
        username: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }],
        password: [{
          required: true,
          message: "请输入密码",
          trigger: "blur"
        }],
        code: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }]
      },
      timer: {}
    };
  },
  components: {
    "v-code": verifyCode
  },
  mounted: function mounted() {
    var _this = this;

    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);
    this.timeInterval = setInterval(function () {
      _this.refreshTime();
    }, 1000);
  },
  beforeDestroy: function beforeDestroy() {
    clearInterval(this.timeInterval);
  },
  created: function created() {
    this.createCode();
  },
  methods: _objectSpread({}, mapActions("d2admin/account", ["login"]), {
    refreshTime: function refreshTime() {
      this.time = dayjs().format("HH:mm:ss");
    },
    isSubmit: function isSubmit() {
      var _this2 = this;

      clearTimeout(this.timer);
      this.timer = setTimeout(function () {
        _this2.submit();
      }, 500);
    },
    changePwd: function changePwd() {
      var data, url, res;
      return regeneratorRuntime.async(function changePwd$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              data = this.pwdForm;
              console.log("menu data", data);
              url = helper.apiHost + "/admin/index/changePwd";
              _context.next = 5;
              return regeneratorRuntime.awrap(axios({
                url: url,
                method: "post",
                data: data
              }));

            case 5:
              res = _context.sent;
              console.log("menu res", res);

              if (res.code == 0) {
                this.$message.success("密码修改成功，请重新登录");
                this.pwdFormVisible = false;
              } else {
                this.$message.error(res.msg);
              }

            case 8:
            case "end":
              return _context.stop();
          }
        }
      }, null, this);
    },

    /**
     * @description 接收选择一个用户快速登录的事件
     * @param {Object} user 用户信息
     */
    // handleUserBtnClick(user) {
    //     this.formLogin.username = user.username
    //     this.formLogin.password = user.password
    //     this.submit()
    // },

    /**
     * @description 提交表单
     */
    // 提交登录信息
    submit: function submit() {
      var _this3 = this;

      var that = this; // console.log(this.formLogin);
      // console.log(this.identifyCode);

      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          // 登录
          // 注意 这里的演示没有传验证码
          // 具体需要传递的数据请自行修改代码
          if (_this3.formLogin.code != _this3.identifyCode) {
            _this3.$message.error("验证码错误");
          } else {
            _this3.login({
              username: _this3.formLogin.username,
              password: Buffer.from(_this3.formLogin.password).toString("Base64")
            }).then(function () {
              console.log("登录完成"); // 重定向对象不存在则返回顶层路径

              _this3.loadMenu();

              _this3.$router.replace(_this3.$route.query.redirect || "/");
            }).catch(function (err) {
              console.log("err: ", err);

              if (err.code == 998) {
                //如果是要改密码
                _this3.$confirm(err.msg, "提示", {
                  confirmButtonText: "修改密码",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(function () {
                  //修改密码
                  _this3.pwdFormVisible = true;
                });
              } //reject(err);

            });
          }
        } else {
          // 登录表单校验失败
          _this3.$message.error("表单校验失败，请检查");
        }
      });
    },
    loadMenu: function loadMenu() {
      var data, url, res;
      return regeneratorRuntime.async(function loadMenu$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              //加载菜单
              data = {
                id: localStorage["admin_id"]
              };
              console.log("menu data", data);
              url = helper.apiHost + helper.apiUrl.menuList;
              _context2.next = 5;
              return regeneratorRuntime.awrap(axios({
                url: url,
                method: "post",
                data: data
              }));

            case 5:
              res = _context2.sent;
              console.log("menu res", res);
              this.$store.commit("d2admin/menu/asideSet", res.data);

            case 8:
            case "end":
              return _context2.stop();
          }
        }
      }, null, this);
    },
    createCode: function createCode() {
      var code = "";
      var codeLength = 4; //验证码的长度

      var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"); //随机数

      for (var i = 0; i < codeLength; i++) {
        //循环操作
        var index = Math.floor(Math.random() * 36); //取得随机数的索引（0~35）

        code += random[index]; //根据索引取得随机数加到code上
      }

      this.checkCode = code; //把code值赋给验证码
    },
    // 点击验证码刷新验证码
    changeCode: function changeCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    // 生成一个随机整数  randomNum(0, 10) 0 到 10 的随机整数， 包含 0 和 10
    randomNum: function randomNum(min, max) {
      max = max + 1;
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 随机生成验证码字符串
    makeCode: function makeCode(data, len) {
      for (var i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)];
      }
    }
  })
};