import util from '@/libs/util.js';
export default {
  namespaced: true,
  mutations: {
    /**
     * @description 显示版本信息
     * @param {Object} state state
     */
    versionShow: function versionShow() {
      util.log.capsule("".concat(process.env.VUE_APP_NAME), "v".concat(process.env.VUE_APP_VERSION));
      console.log('developer  http://www.ycxxkj.com');
      console.log('感谢您的使用，谢谢');
    }
  }
};