import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/web.dom-collections.for-each";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Vue from 'vue';
import helper from '@/libs/helper.js';
import axios from '@/plugin/axios';
import util from '@/libs/util';
var that = null;
import '@/pages/user_analysis/js/china.js';
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart, BarChart, LineChart, MapChart, LinesChart, EffectScatterChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent, VisualMapComponent, GeoComponent } from "echarts/components";
import { LabelLayout, UniversalTransition } from 'echarts/features';
import VChart from "vue-echarts";
use([CanvasRenderer, //渲染
TitleComponent, TooltipComponent, LegendComponent, GridComponent, LabelLayout, VisualMapComponent, //值域组件
GeoComponent, UniversalTransition, PieChart, //饼图
BarChart, //柱状图
LineChart, //折线图
MapChart, //地图
LinesChart, //路线
EffectScatterChart]);
var area_data = {
  '山东': [117.1582, 36.8701],
  '河北': [114.4995, 38.1006],
  '吉林': [125.8154, 44.2584],
  '黑龙江': [127.9688, 45.368],
  '辽宁': [123.1238, 42.1216],
  '内蒙古': [111.4124, 40.4901],
  '新疆': [87.9236, 43.5883],
  '甘肃': [103.5901, 36.3043],
  '宁夏': [106.3586, 38.1775],
  '山西': [112.3352, 37.9413],
  '陕西': [109.1162, 34.2004],
  '河南': [113.4668, 34.6234],
  '安徽': [117.29, 32.0581],
  '江苏': [118.8062, 31.9208],
  '浙江': [119.5313, 29.8773],
  '福建': [119.4543, 25.9222],
  '广东': [113.5107, 23.2196],
  '江西': [116.0046, 28.6633],
  '海南': [110.3893, 19.8516],
  '广西': [108.479, 23.1152],
  '贵州': [106.6992, 26.7682],
  '湖南': [113.0823, 28.2568],
  '湖北': [114.3896, 30.6628],
  '四川': [103.9526, 30.7617],
  '云南': [102.9199, 25.4663],
  '西藏': [91.1865, 30.1465],
  '青海': [101.4038, 36.8207],
  '天津': [117.4219, 39.4189],
  '上海': [121.4648, 31.2891],
  '重庆': [107.7539, 30.1904],
  '北京': [116.4551, 40.2539],
  '香港': [114.12, 22.26],
  '澳门': [113.33, 22.13],
  '台湾': [121.5, 25.05],
  '韶关': [113.7964, 24.7028]
};
export default {
  data: function data() {
    return {
      filename: __filename,
      tableData: [],
      info_height: 775,
      loading: true,
      admin: null,
      system_name: '',
      user_num_data: {
        month: {
          person: 0,
          team: 0
        },
        today: {
          person: 0,
          team: 0
        },
        week: {
          person: 0,
          team: 0
        }
      },
      user_age_18: 0,
      gds: 0,
      sgs: 0,
      b1_option: {
        /*title: {
            text: "风险地区",
            left: "left"
        },*/
        visualMap: {
          show: true,
          left: 'left',
          bottom: 20,
          //值域
          pieces: [{
            value: 2,
            label: '高风险地区',
            color: 'rgba(235,94,18,0.4)'
          }, {
            value: 1,
            label: '中风险地区',
            color: 'rgba(250,173,20,0.3)'
          }, {
            value: 0,
            label: '低风险地区',
            color: 'rgba(30,144,255,0.2)'
          }]
        },
        //地理坐标系组件
        geo: {
          map: 'china',
          show: false,
          roam: false
        },
        //配置属性
        series: [{
          name: '热力地图',
          type: 'map',
          zlevel: 2,
          //地图类型
          mapType: 'china',
          label: {
            normal: {
              show: true //省份名称

            }
            /*emphasis: {
                show: false
            }*/

          },
          data: [{
            name: '北京',
            value: 0
          }, {
            name: '天津',
            value: 0
          }, {
            name: '上海',
            value: 0
          }, {
            name: '重庆',
            value: 0
          }, {
            name: '河北',
            value: 0
          }, {
            name: '河南',
            value: 0
          }, {
            name: '云南',
            value: 0
          }, {
            name: '辽宁',
            value: 0
          }, {
            name: '黑龙江',
            value: 0
          }, {
            name: '湖南',
            value: 0
          }, {
            name: '安徽',
            value: 0
          }, {
            name: '山东',
            value: 0
          }, {
            name: '新疆',
            value: 0
          }, {
            name: '江苏',
            value: 0
          }, {
            name: '浙江',
            value: 0
          }, {
            name: '江西',
            value: 0
          }, {
            name: '湖北',
            value: 0
          }, {
            name: '广西',
            value: 0
          }, {
            name: '甘肃',
            value: 0
          }, {
            name: '山西',
            value: 0
          }, {
            name: '内蒙古',
            value: 0
          }, {
            name: '陕西',
            value: 0
          }, {
            name: '吉林',
            value: 0
          }, {
            name: '福建',
            value: 0
          }, {
            name: '贵州',
            value: 0
          }, {
            name: '广东',
            value: 0
          }, {
            name: '青海',
            value: 0
          }, {
            name: '西藏',
            value: 0
          }, {
            name: '四川',
            value: 0
          }, {
            name: '宁夏',
            value: 0
          }, {
            name: '海南',
            value: 0
          }, {
            name: '台湾',
            value: 0
          }, {
            name: '香港',
            value: 0
          }, {
            name: '澳门',
            value: 0
          }, {
            name: '南海诸岛',
            value: 0
          }]
        }, {
          //必要，用以塑造起点与终点的连线
          name: '韶关路线',
          type: 'lines',
          zlevel: 3,
          //线两端的标记类型，可以是一个数组分别指定两端，也可以是单个统一指定。
          symbol: ['circle', 'circle'],
          symbolSize: 8,
          data: [{
            forName: '上海',
            toName: '韶关',
            coords: [area_data['上海'], area_data['韶关']],
            lineStyle: {
              normal: {
                color: '#7B61FF',
                width: 2,
                opacity: 0.6,
                curveness: 0.3
              }
            }
          }, {
            forName: '北京',
            toName: '韶关',
            coords: [area_data['北京'], area_data['韶关']],
            lineStyle: {
              normal: {
                color: '#7B61FF',
                width: 2,
                opacity: 0.6,
                curveness: 0.3
              }
            }
          }, {
            forName: '黑龙江',
            toName: '韶关',
            coords: [area_data['黑龙江'], area_data['韶关']],
            lineStyle: {
              normal: {
                color: '#7B61FF',
                width: 2,
                opacity: 0.6,
                curveness: 0.3
              }
            }
          }, {
            forName: '香港',
            toName: '韶关',
            coords: [area_data['香港'], area_data['韶关']],
            lineStyle: {
              normal: {
                color: '#7B61FF',
                width: 2,
                opacity: 0.6,
                curveness: 0.3
              }
            }
          }, {
            forName: '澳门',
            toName: '韶关',
            coords: [area_data['澳门'], area_data['韶关']],
            lineStyle: {
              normal: {
                color: '#7B61FF',
                width: 2,
                opacity: 0.6,
                curveness: 0.3
              }
            }
          }, {
            forName: '台湾',
            toName: '韶关',
            coords: [area_data['台湾'], area_data['韶关']],
            lineStyle: {
              normal: {
                color: '#7B61FF',
                width: 2,
                opacity: 0.6,
                curveness: 0.3
              }
            }
          }]
        }]
      },
      b1_table_data: [],
      b2_option: {
        width: 160,

        /*title: {
            text: "游客男女比例分析",
            left: "left"
        },*/
        legend: {
          left: 'right',
          top: 'middle',
          orient: 'vertical',
          icon: 'circle'
        },
        series: [{
          width: 160,
          left: 'center',
          name: '游客来源',
          type: 'pie',
          radius: ['90%', '100%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '24',
              fontWeight: 'bold',
              formatter: '{d}%',
              align: 'center'
            }
          },
          data: [{
            name: '男',
            value: 1000,
            itemStyle: {
              color: '#2E51FF'
            }
          }, {
            name: '女',
            value: 2000,
            itemStyle: {
              color: '#84F3F9'
            }
          }]
        }]
      },
      b3_status: 0,
      b3_user_data: [{
        name: '张三',
        begin_time: '09:00:00',
        end_time: '10:00:00',
        writeoff_time: '2021-10-12 10:00:00',
        num: 1
      }, {
        name: '李四',
        begin_time: '09:00:00',
        end_time: '10:00:00',
        writeoff_time: '2021-10-12 10:00:00',
        num: 2
      }, {
        name: '王五',
        begin_time: '09:00:00',
        end_time: '10:00:00',
        writeoff_time: '2021-10-12 10:00:00',
        num: 3
      }],
      b3_team_data: [{
        name: '张三',
        begin_time: '09:00:00',
        end_time: '10:00:00',
        writeoff_time: '2021-10-12 10:00:00',
        num: 1
      }, {
        name: '李四',
        begin_time: '09:00:00',
        end_time: '10:00:00',
        writeoff_time: '2021-10-12 10:00:00',
        num: 2
      }, {
        name: '王五',
        begin_time: '09:00:00',
        end_time: '10:00:00',
        writeoff_time: '2021-10-12 10:00:00',
        num: 3
      }],
      c1_option: {
        /*title: {
            text: "游客年龄分布",
            left: "left"
        },*/
        width: '75%',
        legend: {
          left: '87%',
          top: 'middle',
          orient: 'vertical',
          icon: 'circle'
        },
        xAxis: {
          type: 'category',
          data: ['14岁以下', '15-24', '25-34', '35-44', '45-54', '55-64', '65岁以上']
        },
        yAxis: {
          type: 'value'
        },
        label: {
          show: false
        },
        series: [{
          name: '男性',
          type: 'bar',
          data: [10, 30, 38, 47, 34, 21, 7],
          stack: 'total',
          itemStyle: {
            color: '#2E51FF'
          }
        }, {
          name: '女性',
          type: 'bar',
          data: [3, 5, 17, 21, 10, 8, 3],
          stack: 'total',
          itemStyle: {
            color: '#84F3F9'
          }
        }]
      }
    };
  },
  components: {
    VChart: VChart
  },
  created: function created() {
    that = this;
    this.loadData('', '');
  },
  methods: {
    loadData: function loadData(periodDate, dir) {
      var that, url, data, response, resData, risk_area_data, risk_area_index, lines_data, user_area;
      return regeneratorRuntime.async(function loadData$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              that = this;
              that.loading = true;
              url = helper.apiHost + helper.apiUrl.getIndexData;
              data = {};
              _context.next = 6;
              return regeneratorRuntime.awrap(axios(url, {
                params: data
              }));

            case 6:
              response = _context.sent;
              that.loading = false;

              if (response.code == 0) {
                console.log('loadData:response', response);
                resData = response['data'];
                that.admin = resData['admin'];
                that.system_name = resData['system_name'];
                that.user_num_data = resData['user_num_data'];
                that.user_age_18 = resData['user_age_18'];
                that.gds = resData['gds'];
                that.sgs = resData['sgs'];
                that.b2_option.series[0].data[0].value = resData['user_gender_data']['gender_1'];
                that.b2_option.series[0].data[1].value = resData['user_gender_data']['gender_2'];
                that.b3_user_data = resData['new_person_order_data'];
                that.b3_team_data = resData['new_team_order_data'];
                that.c1_option.series[0].data = resData['user_age_data']['user_age_1'];
                that.c1_option.series[1].data = resData['user_age_data']['user_age_2'];
                risk_area_data = that.b1_option.series[0].data;
                risk_area_index = resData['risk_area_index'];
                risk_area_data.forEach(function (item) {
                  if (risk_area_index[item['name']]) {
                    item['value'] = risk_area_index[item['name']];
                  }
                });
                that.b1_option.series[0].data = risk_area_data;
                lines_data = [];
                user_area = resData['user_area'];
                user_area.forEach(function (item) {
                  lines_data.push({
                    forName: item['name'],
                    toName: '韶关',
                    coords: [area_data[item['name']], area_data['韶关']],
                    lineStyle: {
                      normal: {
                        color: '#7B61FF',
                        width: 2,
                        opacity: 0.6,
                        curveness: 0.3
                      }
                    }
                  });
                });
                that.b1_option.series[1].data = lines_data;
                that.b1_table_data = resData['user_area_2'];
              } else {}

            case 9:
            case "end":
              return _context.stop();
          }
        }
      }, null, this);
    },
    openPage: function openPage(path) {
      console.log('openPage', path);
      this.$router.push(path);
    }
  }
};